import FeaturedPosts from "@/components/FeaturedPosts";
import FeaturedProvider from "@/components/FeaturedProvider";
import Header from "@/components/Header";
import Layout from "@/components/Layout";
import FeatureExtra from "components/FeatureExtra";
import { getFeaturedPosts } from "utils/blogApi";
import { fetchFeaturedProviders, fetchPageContent } from "utils/fetchApi";
import { formatToBlogDate } from "utils/helpers";
import { truncateString } from "utils/textFormatter";

export default function Home({ heroContent, providers, posts }) {
  return (
    <Layout>
      <Header browserTitle="WellFitly: Empowering Your Wellness Journey with the Right Professionals" />
      <FeatureExtra />
      <FeaturedProvider providers={providers} />
      <FeaturedPosts posts={posts} />
    </Layout>
  );
}

export const getStaticProps = async () => {
  const providers = await fetchFeaturedProviders();
  const posts = await getFeaturedPosts();

  return {
    props: {
      providers,
      posts: posts.map((post) => ({
        ...post,
        summary: truncateString(post.summary, 100),
        created_at: formatToBlogDate(post.created_at),
      })),
    },
    revalidate: 900,
  };
};
