import {
  Box,
  Center,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import HeadingTitle from "./HeadingTitle";
import {
  FcCollaboration,
  FcPortraitMode,
  FcOrganization,
  FcConferenceCall,
  FcPlanner,
} from "react-icons/fc";

const Card = ({ heading, description, icon, id, href = "#" }) => {
  return (
    <Box
      maxW={{ base: "full", md: "275px" }}
      w={"full"}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}
    >
      <Link id={id} href={href}>
        <Stack align={"start"} spacing={2}>
          <Flex
            w={16}
            h={16}
            align={"center"}
            justify={"center"}
            color={"white"}
            rounded={"full"}
            bg={useColorModeValue("gray.100", "gray.700")}
          >
            {icon}
          </Flex>
          <Box mt={2}>
            <Heading as={"h3"} fontSize={"xl"} fontFamily={"YesevaOne"}>
              {heading}
            </Heading>
            <Text mt={1} fontSize={"sm"}>
              {description}
            </Text>
          </Box>
        </Stack>
      </Link>
    </Box>
  );
};

export default function FeaturedExtra() {
  return (
    <Box p={4} mt={8}>
      <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
        <HeadingTitle mainTitle={"Why Join WellFitly?"} />
        <Text color={"gray.600"} fontSize={{ base: "sm", sm: "lg" }}>
          Connect with top-tier health professionals, receive personalized
          guidance, and become part of a supportive community. Discover
          transformative workouts, expert nutrition advice, events, and holistic
          self-care practices all in one place.
        </Text>
      </Stack>

      <Container maxW={"5xl"} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          <Card
            id="for-individuals"
            href="/getting-started/individuals"
            heading={"For Individuals"}
            icon={<Icon as={FcPortraitMode} w={10} h={10} />}
            description={
              "Connect with verified trainers/coaches/experts who are passionate about your well-being."
            }
          />
          <Card
            id="for-professionals"
            href="/getting-started/providers"
            heading={"For Professionals"}
            icon={<Icon as={FcConferenceCall} w={10} h={10} />}
            description={
              "WellFitly provides an easy and effective way for you to find new clients, and collaborate with brands."
            }
          />
          <Card
            id="about-locations"
            href="/about/locations"
            heading={"Find Locations"}
            icon={<Icon as={FcOrganization} w={10} h={10} />}
            description={
              "Get access to a variety of verified locations to workout, meditate, and more."
            }
          />
          <Card
            id="about-events"
            href="/about/events"
            heading={"Find Events"}
            icon={<Icon as={FcPlanner} w={10} h={10} />}
            description={
              "Get notified about upcoming exclusive events, group workouts, and more."
            }
          />
          <Card
            id="about-collaborations"
            href="/about/brand-collaboration"
            heading={"Brand Collaboration"}
            icon={<Icon as={FcCollaboration} w={10} h={10} />}
            description={
              "Collaborate with brands, and also get access to exclusive discounts and offers."
            }
          />
        </Flex>
      </Container>

      <Center>
        <Stack
          mt={10}
          spacing={6}
          p={10}
          bg={"black"}
          rounded={10}
          align={"center"}
        >
          <Text
            fontFamily={"Yeseva"}
            fontSize={"2xl"}
            fontWeight={"bold"}
            color={"white"}
          >
            Are you a wellness coach, fitness trainer, or sports coach?
          </Text>
          <Text color={"white"}>
            {" "}
            Creating a profile on WellFitly puts you on the radar for discovery.
          </Text>
          <Button
            as="a"
            id="btn-footer-create-account"
            href="/sign-up/pro"
            rounded={"full"}
            px={6}
            bg={"gray.100"}
            _hover={{ bg: "gray.400", color: "black" }}
          >
            CREATE A PRO ACCOUNT
          </Button>
        </Stack>
      </Center>
    </Box>
  );
}
