import { useEffect, useState } from "react";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { COLLECTIONS, FIELDS, defaultProviderListQuery } from "utils/constants";

export const getProfile = async (supabaseClient, userId) => {
  const { data, error } = await supabaseClient
    .from(COLLECTIONS.profile)
    .select(defaultProviderListQuery)
    .eq(FIELDS.id, userId);

  if (error) throw new Error(`Failed to get user profile: ${error.message}`);

  if (!error && data.length > 0) {
    return data[0];
  }
};

const getProfileInboxCount = async (supabaseClient, userId) => {
  const { count } = await supabaseClient
    .from(COLLECTIONS.inbox)
    .select("*", { count: "exact" })
    .eq(FIELDS.recipient, userId)
    .eq(FIELDS.read, false)
    .eq(FIELDS.deleted, false);
  return count;
};

export const updateProfile = async (supabaseClient, userId, payload) => {
  const latest = await getProfile(supabaseClient, userId); // hack to get latest profile
  const data = { ...latest, ...payload };
  try {
    await supabaseClient.from(COLLECTIONS.profile).upsert(data);
    return data;
  } catch (error) {
    throw new Error(`Failed to update user profile: ${error.message}`);
  }
};

const useProfile = (user) => {
  const supabaseClient = useSupabaseClient();
  const [profile, setProfile] = useState({});
  const [inboxCount, setInboxCount] = useState(0);
  const [status, setStatus] = useState({
    isLoading: false,
    isSaving: false,
    error: null,
  });

  const { isLoading, isSaving, error } = status;

  useEffect(() => {
    if (user) {
      setStatus((prev) => ({ ...prev, isLoading: true }));
      getProfile(supabaseClient, user.id)
        .then((data) => {
          setProfile(data);
          setStatus((prev) => ({ ...prev, isLoading: false }));
        })
        .catch((error) =>
          setStatus((prev) => ({ ...prev, isLoading: false, error }))
        );

      getProfileInboxCount(supabaseClient, user.id).then((data) => {
        setInboxCount(data);
      });
    }
  }, [supabaseClient, user]);

  const updateProfileWrapper = async (payload) => {
    setStatus((prev) => ({ ...prev, isSaving: true }));
    try {
      const data = await updateProfile(supabaseClient, user.id, payload);
      setProfile(data);
      setStatus((prev) => ({ ...prev, isSaving: false }));
    } catch (error) {
      setStatus((prev) => ({ ...prev, isSaving: false, error }));
    }
  };

  return {
    profile,
    inboxCount,
    updateProfile: updateProfileWrapper,
    isLoading,
    isSaving,
    error,
  };
};

export default useProfile;
