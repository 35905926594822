import { Divider, SimpleGrid, Container } from "@chakra-ui/react";
import HeadingTitle from "./HeadingTitle";
import PostCard from "./PostCard";

const FeaturedPosts = ({ posts }) => {
  return (
    <Container maxW={"7xl"} pb={20}>
    <HeadingTitle mainTitle="WellFitly Recent Articles" subTitle="Discover new ways to stay well and fit, and be more productive!"/>
      <Divider marginTop="5" />
      <SimpleGrid
        columns={{ base: 1, md: 1, lg: 3 }}
        spacing={5}
        px={{ base: 2, md: 4, lg: 4 }}
        pt={4}
      >
        {posts.map((post) => (
          <PostCard post={post} key={post.id} />
        ))}
      </SimpleGrid>
    </Container>
  );
};

export default FeaturedPosts;
