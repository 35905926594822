import { memo } from "react";
import { Heading, Stack, Text } from "@chakra-ui/react";

function HeadingTitle({ mainTitle, subTitle, align = "center", ...rest }) {
  return (
    <Stack {...rest} spacing={0} align={align}>
      <Heading fontFamily={"YesevaOne"}>{mainTitle}</Heading>
      {subTitle && <Text>{subTitle}</Text>}
    </Stack>
  );
}

export default memo(HeadingTitle);
