import {
  Box,
  Image,
  Text,
  Stack,
  Flex,
  Heading,
  useBreakpointValue,
} from "@chakra-ui/react";
import StyledButton from "./StyledButton";
import { FaCheckCircle } from "react-icons/fa";
import { toTitleCase } from "utils/textFormatter";
import { FiMapPin } from "react-icons/fi";
import StyledBadge from "./StyledBadge";

function ProfileDisplayCard({ data, onSelect, hasAction, bg = "#FFFFFF" }) {
  // Responsive font size
  const fontSize = useBreakpointValue({ base: "sm", md: "md" });

  const {
    firstname,
    about,
    avatar,
    expertise,
    city,
    state,
    location,
    verified,
  } = data;

  return (
    <Box borderWidth="1px" borderRadius="lg" p={3} bg={bg} mt={2}>
      <Flex direction={["column", "column", "column", "row"]}>
        <Image
          borderRadius={10}
          minWidth={250}
          maxWidth={{ base: "100%", lg: 250}}
          height={250}
          objectFit={"cover"}
          objectPosition={"center"}
          src={avatar}
          alt={firstname}
        />
        <Box px={3} mt={4}>
          <Stack direction={"row"} spacing={2}>
            <Heading fontSize={"lg"} fontFamily={"YesevaOne"}>
              {toTitleCase(firstname)}
            </Heading>
            {verified ? <FaCheckCircle /> : null}
          </Stack>
          <Box py={1}>
            <StyledBadge
              rounded={10}
              colorScheme={"green"}
              text={expertise || "Fitness Pro"}
            />
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <FiMapPin />
            <Text
              marginLeft={1}
              fontSize={"sm"}
              color={"gray.700"}
              casing={"uppercase"}
            >
              {city && state
                ? `${city}, ${state}`
                : location || state || city || "The Gym"}
            </Text>
          </Box>
          <Text mt={2} fontSize={fontSize}>
            {about}
          </Text>
          <Box mt={4}>
            <StyledButton
              onClick={() => onSelect(data)}
              text={"View Profile"}
            />
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}

export default ProfileDisplayCard;
