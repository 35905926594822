import { useState, useCallback, memo, useEffect } from "react";
import {
  Center,
  CircularProgress,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import ProfileModal from "components/ProfileModal";
import { FaArrowDown, FaSmileWink } from "react-icons/fa";
import NoContent from "./NoContent";
import { getProfile } from "hooks/useProfile";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useRouter } from "next/router";
import ProfileDisplayCard from "./ProfileDisplayCard";
import StyledButton from "./StyledButton";

const ProvidersList = ({
  data,
  hasMore,
  error,
  fetchNext = () => {},
  loading = true,
  usePagination = false,
  hasAction = true,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [profile, setProfile] = useState(null);
  const supabaseClient = useSupabaseClient();
  const router = useRouter();
  const popupId = router.query.popupId;

  const onProfileSelect = useCallback(
    (profile) => {
      setProfile(profile);
      onOpen();
      router.push(
        {
          pathname: router.asPath.split("?")[0], // Gets the current path without query parameters
          query: { ...router.query, popupId: profile.id }, // Spread existing query params and add the popupId
        },
        undefined,
        {
          shallow: true,
        }
      );
    },
    [onOpen, router]
  );

  const onCloseModal = () => {
    setProfile(null);
    onClose();

    const { popupId, ...restQueryParams } = router.query; // Destructure out the popupId
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...restQueryParams, // Spread the remaining query params
        },
      },
      undefined,
      {
        shallow: true,
      }
    );
  };

  useEffect(() => {
    if (popupId) {
      getProfile(supabaseClient, popupId).then((profileData) => {
        if (profileData) {
          setProfile(profileData);
          onOpen();
        }
      });
    }
  }, [popupId, onOpen, supabaseClient]);

  return (
    <Stack
      maxW={{ base: "full", md: "7xl" }}
      minH={"100vh"}
      mx={{ base: 0, md: "auto" }}
    >
      {error && (
        <Stack align={"center"}>
          <Text as="h2" color={"red.500"} fontSize="lg">
            {error}
          </Text>
        </Stack>
      )}
      <Stack padding={4}>
        {data?.length > 0 ? (
          <>
            {data.map((profile) => (
              <ProfileDisplayCard
                key={profile.id}
                data={profile}
                onSelect={onProfileSelect}
                hasAction={hasAction}
              />
            ))}
          </>
        ) : loading ? (
          <Stack align={"center"} mt={20}>
            <CircularProgress mx={"auto"} isIndeterminate color="black" />
          </Stack>
        ) : (
          <Stack align={"center"}>
            <NoContent text={"Nothing to see here"} />
          </Stack>
        )}
        {usePagination && hasMore && (
          <Center>
            <Stack mt={8} mb={8} direction={"row"} spacing={4}>
              <StyledButton
                isLoading={loading}
                rightIcon={loading ? <FaSmileWink/> : <FaArrowDown />}
                disabled={loading}
                text={loading ? "Loading more" : "Load More"}
                onClick={fetchNext}
              />
            </Stack>
          </Center>
        )}
      </Stack>
      {profile && (
        <ProfileModal
          profile={profile}
          isOpen={isOpen}
          onClose={onCloseModal}
        />
      )}
    </Stack>
  );
};

export default memo(ProvidersList);
