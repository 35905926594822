import { FaCheck } from "react-icons/fa";

const { Badge, Flex, Text } = require("@chakra-ui/react");

const StyledBadge = ({
  leftIcon,
  showIcon,
  colorScheme = "green",
  text,
  marginLeft = 0,
}) => {
  return (
    <Badge colorScheme={colorScheme} rounded={10} px={2} ml={marginLeft}>
      <Flex gap={1} alignItems={"center"}>
        {showIcon && leftIcon} <Text>{text}</Text>
      </Flex>
    </Badge>
  );
};

export default StyledBadge;
