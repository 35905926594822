import {
  Box,
  Heading,
  Image,
  Text,
  useColorModeValue,
  Stack,
  HStack,
  Tag,
  Divider,
  Link,
} from "@chakra-ui/react";
import { toTitleCase } from "utils/textFormatter";

export const BlogTags = (props) => {
  const { marginTop = 0, post } = props;

  return (
    <HStack spacing={2} marginTop={marginTop}>
      return (
      <Link href={`/blog/category/${post.category_slug}`}>
        <Tag size={"md"} variant="solid" colorScheme="gray">
          {post.category}
        </Tag>
      </Link>
      );
    </HStack>
  );
};

export const BlogAuthor = ({ avatar, name, date }) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src={avatar}
        alt={name}
      />
      <Text fontWeight="medium">{name}</Text>
      <Text>—</Text>
      <Text>{date}</Text>
    </HStack>
  );
};

const BlogCard = ({ post }) => {
  return (
    <Stack>
      <Box
        marginTop={{ base: "1", sm: "5" }}
        display="flex"
        flexDirection={{ base: "column", sm: "row" }}
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center"
        >
          <Box
            width={{ base: "100%", sm: "85%" }}
            zIndex="0"
            marginLeft={{ base: "0", sm: "5%" }}
            marginTop="5%"
          >
            <Box textDecoration="none" _hover={{ textDecoration: "none" }}>
              <Link href={`/blog/${post.slug}`}>
                <Image
                  borderRadius="lg"
                  src={post.cover}
                  alt={post.title}
                  objectFit="cover"
                  objectPosition="top"
                  height={"400px"}
                  width="100%"
                  transform="scale(1.0)"
                  transition="0.3s ease-in-out"
                  _hover={{
                    transform: "scale(1.05)",
                  }}
                />
              </Link>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: "3", sm: "0" }}
        >
          <BlogTags post={post} marginTop={3} />
          <Heading marginTop="1">
            <Link href={`/blog/${post.slug}`}>
              <Text
                fontFamily="Yeseva"
                textDecoration="none"
                _hover={{ textDecoration: "none" }}
              >
                {toTitleCase(post.title)}
              </Text>
            </Link>
          </Heading>
          <Stack>
            <Text
              as="p"
              marginTop="2"
              color={useColorModeValue("gray.700", "gray.200")}
              fontSize="lg"
            >
              {post.summary}
            </Text>
            <BlogAuthor
              name={post.author}
              date={post.created_at}
              avatar={post.avatar}
            />
          </Stack>
        </Box>
      </Box>
      <Divider py={4} />
    </Stack>
  );
};

export default BlogCard;
