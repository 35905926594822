import { memo } from "react";
import { Container } from "@chakra-ui/react";
import HeadingTitle from "./HeadingTitle";
import ProvidersList from "./ProvidersList";

const FeaturedProvider = ({ providers }) => {
  return (
    <Container maxW={"7xl"} minH={"50vh"} py={4}>
      <HeadingTitle mb={8} mainTitle={"Featured Professionals"} />
      <ProvidersList data={providers} hasAction={true} />
    </Container>
  );
};

export default memo(FeaturedProvider);
