import { memo } from "react";
import HeadingTitle from "@/components/HeadingTitle";
import { Box, Image } from "@chakra-ui/react";
const NoContent = ({ text }) => {
  return (
    <Box mt={50}>
      <Image
        src={"/empty-box.png"}
        alt={"Empty"}
        maxW="120px"
        mx="auto"
        fallbackSrc="/empty-box.png"
      />
      {text ? <HeadingTitle mainTitle={text} color="gray.400" /> : null}
    </Box>
  );
};

export default memo(NoContent);
