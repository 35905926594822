import { Box, Heading, Image, Text, Link } from "@chakra-ui/react";
import { BlogAuthor, BlogTags } from "./BlogCard";

const PostCard = ({ post }) => {
  return (
    <Box>
      <Box borderRadius="lg" overflow="hidden">
        <Box textDecoration="none" _hover={{ textDecoration: "none" }}>
          <Link href={`/blog/${post.slug}`}>
            <Image
              src={post.cover}
              alt={post.title}
              objectFit="cover"
              objectPosition="top"
              height={"400px"}
              width="100%"
              transform="scale(1.0)"
              transition="0.3s ease-in-out"
              _hover={{
                transform: "scale(1.05)",
              }}
            />
          </Link>
        </Box>
      </Box>
      <BlogTags post={post} marginTop={3} />
      <Heading fontSize="2xl" marginTop="2" fontFamily={"Yesava"}>
        <Link href={`/blog/${post.slug}`}>
          <Text textDecoration="none" _hover={{ textDecoration: "none" }}>
            {post.title}
          </Text>
        </Link>
      </Heading>
      <Text my={2}>{post.summary}</Text>
      <BlogAuthor
        name={post.author}
        date={post.created_at}
        avatar={post.avatar}
      />
    </Box>
  );
};

export default PostCard;
